import dayjs from 'dayjs';
import axios from 'axios';
import get from 'lodash/get';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { clearLocalStorage } from 'legacy-packages/ft-cb';
import { userRoles } from 'legacy/src/utility/User';
import { storeUserType } from 'legacy/src/utility/utils';
import {
  EDUCATIONAL_PERIOD_PREFERENCE,
  PREFERENCE_CONTEXT_SUBJECT,
} from 'legacy/src/constants/types';
import { throttle } from 'lodash';
import { LC_CONSTANTS } from 'legacy/src/components/impersonating/utils/constants';
import {
  getUserLocalStorageKeys,
  isImpersonating,
} from 'legacy/src/components/impersonating/utils';
import log from 'chameleon/ui-stack/utilities/log';
import LOGOUT_WHITELIST from 'chameleon/ui-stack/constants/lc-logout-whitelist';

export const LOCKDOWN_PAGES = [
  'lockdown', // Used for downloading Lockdown Browser
  'cf_dl', // Used for downloading Lockdown Browser (S3 Download)
  'securebrowsercheck', // Used for checking Lockdown Browser
  'close', // Used for closing Lockdown Browser
  'readinesscheck', // Used to show Lockdown's Test Exam (to verify everything works)
];

export const STANDALONE_PAGES = [
  ...LOCKDOWN_PAGES,
  'ps-answer-sheets',
  'login',
  'version',
  'logout',
  'join',
];

const cookies = new Cookies();

export const getCbSession = () => {
  const globalCB = window.cb;
  return (
    (globalCB &&
      globalCB.core &&
      globalCB.core.iam &&
      globalCB.core.iam.getAuthSession()) ||
    {}
  );
};

// relies on the window object
export const timeLeftUntilCBSessionExpire = () => {
  const cbSessionExpiration = get(getCbSession(), 'expireTimeInMS', null);
  const currentTime = dayjs();
  const expirationTime = dayjs(cbSessionExpiration);
  return expirationTime.diff(currentTime, 'minute');
};

// relies on the window object
// returns time left in minutes or null if session does not exist
export const timeLeftUntilCbSessionTimeout = () => {
  // levity idle timeout refreshes expiration every 15 minutes after the 2 hours up to a maximum of 6 hours
  const cbSessionCreation = get(getCbSession(), 'createTimeInMS', null);
  if (cbSessionCreation) {
    const currentTime = dayjs();
    const expirationTime = dayjs(cbSessionCreation).add(6, 'hour');
    return expirationTime.diff(currentTime, 'minute');
  }
  return timeLeftUntilCBSessionExpire();
};

document.addEventListener(
  'visibilitychange',
  () => {
    const splitPathname = window.location.pathname.split('/');
    const shouldRedirect = !STANDALONE_PAGES.includes(splitPathname[1]); // 0 index is empty as `pathname` begins with a `/`

    if (!document.hidden && shouldRedirect) {
      axios({
        method: 'get',
        url: `${FYM_ACCOUNTS_URL}/auth/verify/`,
      });
    }
  },
  false,
);

/**
 * Log you in using the levity session if it exists
 */
export const loginWithLevity = (cbSession, useRedirectUrl) =>
  new Promise((resolve) => {
    axios({
      method: 'post',
      url: `${FYM_ACCOUNTS_URL}/account/api/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        namespace: cbSession.basicProfile.namespace,
        sessionId: cbSession.sessionId,
        username: cbSession.basicProfile.userName,
      },
    })
      .then((resp) => {
        // should return access+refresh token
        if (resp.status === 200) {
          const { accessTokenKey, refreshTokenKey, expiresKey } =
            getUserLocalStorageKeys();

          if (
            useRedirectUrl &&
            !isImpersonating() &&
            resp.data.cb_redirect_url
          ) {
            window.location.href = resp.data.cb_redirect_url;
          }

          window.localStorage.setItem(accessTokenKey, resp.data.access_token);
          window.localStorage.setItem(refreshTokenKey, resp.data.refresh_token);
          window.localStorage.setItem(
            expiresKey,
            Date.parse(resp.data.expires),
          );
          window.localStorage.setItem('cb_session_id', cbSession.sessionId);
          window.localStorage.setItem('session_id', uuidv4());
          return resolve(true);
        } else if (resp.status === 400 && resp.data.error === 'MISSING APUID') {
          // If the student has no apuid it means they have not been enrolled yet.
          // Beyond this point error.js will expect userData.me which doesnt exist when 400 is returned
          // Also, user_role will not have been set which means there’s no way to determine the
          // error message to display, which means best way to deal with this is redirect the student so they can enroll
          window.location.href = `${ROS_FRONTEND_URL}/dashboard`;
          return;
        } else if (
          resp.status === 400 &&
          resp.data &&
          resp.data.message &&
          resp.data.message.error ===
            'The user has not accepted the terms and conditions'
        ) {
          window.location.href = ROS_FRONTEND_URL;
          return;
        }

        log('error', resp.data);
        return resolve(false);
      })
      .catch((err) => {
        log('error', err);
        return resolve(false);
      });
  });
/**
 * Performs redirect to login upon errors.
 * @param {string} redirectPath Allows for specifying redirect path.
 * @param {object} router - optional
 */
export function redirectToLogin(redirectPath = '', router) {
  /**
   * Set the redirect_path so long as path is not version, login, logout, join, auth/redirect or root.
   */
  let path = `${FYM_FRONTEND_URL}/login`;

  const split = redirectPath.split('/');

  const redirect =
    !/(version$)|(login$)|(close$)|(lockdown$)|(cf_dl$)|(securebrowsercheck$)|(logout$)|(join$)|(auth\/redirect$)|(^\/$)/i.test(
      split[1],
    );
  // ^ 0 index is empty as `pathname` begins with a `/`

  if (redirectPath && redirect) {
    path += `?redirect_path=${redirectPath}`;
  }

  if (router) {
    router.replace(redirectPath || '/login');
  } else {
    window.location.href = path;
  }
}

/**
 * Check if a route is available, redirect
 * @param {string} routePath The current route to test against the list
 * @param {array} navData The nav list
 * @param {object} restrictedRoutes
 * @return {boolean} evaluates with true or false if the route is within the routes list
 * */
export const isRouteAvailable = (routePath, navData, restrictedRoutes = {}) => {
  const route = routePath.replace('_', ' ').toUpperCase();

  const assessments = 'ASSESSMENTS';
  const customRoutes = {
    Home: 'HOME',
    'Progress Checks': assessments,
    Assessments: assessments,
    'My Classes': 'CLASSES',
    'Progress Dashboard': 'DASHBOARD',
  };

  return _.find(navData, (item) => {
    const customRoute =
      customRoutes[item.navName] === route ? item.navName : route;
    const restrictedRoute = restrictedRoutes[item.navName] === route || false;
    const isAggregateReport = route === 'AGGREGATE';
    const isResourcesRoute = route === 'RESOURCES';

    return (
      (item.navName.toUpperCase() === customRoute.toUpperCase() ||
        isAggregateReport ||
        isResourcesRoute) &&
      !restrictedRoute
    );
  });
};

/**
 * Redirect to home path is not available
 * @param {object} props Properties with route attributes
 * */
export const redirectNoRouteAccess = (props) => {
  const { params, route, router, navData, restrictedRoutes, isStudent } = props;

  if (!isRouteAvailable(route, navData, restrictedRoutes, isStudent)) {
    router.replace(`/${params.subject}/404-not-found`);
  }
};

/**
 * Redundant thing, needed to be removed later.
 * Sets the user variable to global context.
 * @param {object} me - user object.
 **/
export const setGlobalCurrentUser = (me) => {
  if (me) {
    /**
     * Set /me response to global variable.
     */
    const roles = userRoles.set(me.userRole);
    window.current_user = me;
    /**
     * Set user type to localStorage.
     */
    storeUserType(roles);
  }
};

export const findGroupId = (userSubjects, subjectId) =>
  userSubjects.find((userSubject) => userSubject.id === subjectId)?.subjects[0]
    ?.initId;

export const getPeriodObjFromValue = (val, educationPeriods = []) => {
  let period;
  educationPeriods.forEach((ed) => {
    if (parseInt(val, 10) === parseInt(ed.id, 10)) {
      period = ed;
    }
  });
  return period;
};

const getStoredPreference = ({ me, userSubjects, subject, preference }) => {
  if (me && userSubjects && subject) {
    const { preferences } = me;
    const groupId = findGroupId(userSubjects, subject);

    if (!preferences) {
      return null;
    }

    let storedPref = null;
    preferences.forEach((pref) => {
      const {
        preference: storedPreferenceType,
        context,
        contextId,
        value,
      } = pref;
      const rightPrefType =
        storedPreferenceType === preference &&
        context === PREFERENCE_CONTEXT_SUBJECT;
      if (rightPrefType && parseInt(contextId, 10) === parseInt(groupId, 10)) {
        storedPref = value;
      }
    });
    return storedPref;
  }

  return '';
};

export const findChosenEducationPeriod = ({
  currentEducationPeriod,
  subjectEducationPeriods,
  me,
  userSubjects,
  subject,
}) => {
  if (subjectEducationPeriods.length < 1) {
    return currentEducationPeriod;
  }

  let chosen = getStoredEducationPeriodPreference({
    me,
    educationPeriods: subjectEducationPeriods,
    userSubjects,
    subject,
  });

  if (!chosen) {
    chosen = getPeriodObjFromValue(
      currentEducationPeriod.id,
      subjectEducationPeriods,
    );
  }
  return chosen;
};

export const getStoredEducationPeriodPreference = ({
  me,
  educationPeriods,
  userSubjects,
  subject,
}) => {
  if (educationPeriods) {
    const preference = getStoredPreference({
      me,
      educationPeriods,
      userSubjects,
      subject,
      preference: EDUCATIONAL_PERIOD_PREFERENCE,
    });

    return getPeriodObjFromValue(preference, educationPeriods);
  }

  return null;
};

export const getSubjectEducationPeriods = ({ subjectId, teacherSubjects }) => {
  if (!teacherSubjects) {
    return [];
  }

  const teacherSubject = teacherSubjects.find(
    (s) => parseInt(s.id, 10) === parseInt(subjectId, 10),
  );
  return (
    (teacherSubject &&
      teacherSubject.educationPeriods &&
      teacherSubject.educationPeriods.length &&
      teacherSubject.educationPeriods) ||
    []
  );
};

export const getEducationPeriodForSubject = ({ subjectId, userData }) => {
  const {
    teacherSubjects,
    educationPeriod: { currentEducationPeriod, nextEducationPeriod },
    chosenEducationPeriodCode,
  } = userData;

  const subjectEducationPeriods = getSubjectEducationPeriods({
    subjectId,
    teacherSubjects,
  });

  const subjectHasCurrentEducationPeriod =
    !!currentEducationPeriod &&
    !!subjectEducationPeriods.find(
      (subjectEducationPeriod) =>
        subjectEducationPeriod.id === currentEducationPeriod.id,
    );
  const subjectHasNextEducationPeriod =
    !!nextEducationPeriod &&
    !!subjectEducationPeriods.find(
      (subjectEducationPeriod) =>
        subjectEducationPeriod.id === nextEducationPeriod.id,
    );

  const hasAccessToAnyPeriod = !!(
    subjectEducationPeriods && subjectEducationPeriods.length
  );
  const latestPeriodWithAccess =
    hasAccessToAnyPeriod &&
    subjectEducationPeriods
      .slice()
      .sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

  const educationPeriod =
    chosenEducationPeriodCode ||
    (subjectHasCurrentEducationPeriod && currentEducationPeriod.id) ||
    (subjectHasNextEducationPeriod && nextEducationPeriod.id) ||
    (hasAccessToAnyPeriod && latestPeriodWithAccess[0].id);

  return educationPeriod || '';
};

export const accessTokenRefresh = (props) => {
  const { refreshTokenKey, accessTokenKey, expiresKey } = props || {
    refreshTokenKey: LC_CONSTANTS.RT,
    accessTokenKey: LC_CONSTANTS.AT,
    expiresKey: LC_CONSTANTS.EXP,
  };

  const accessToken = window.localStorage.getItem(accessTokenKey);
  const refreshToken = window.localStorage.getItem(refreshTokenKey);
  // make a new request here so that we can set headers
  // you have to set headers before you send the actual request - otherwise they wont show
  const getRefresh = axios.create({
    baseURL: `${FYM_ACCOUNTS_URL}/token/refresh/`,
    data: { access_token: accessToken },
  });
  //set headers
  getRefresh.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  const prom = getRefresh
    .post()
    .then((resp) => {
      if (resp.data.access_token) {
        //got new account_access_token - set it to storage
        window.localStorage.setItem(accessTokenKey, resp.data.access_token);
        window.localStorage.setItem(expiresKey, Date.parse(resp.data.expires));
        return resp;
      }
      throw new Error('Error processing the request. Please login again.');
    })
    .catch((err) => {
      console.error('Refresh error', err); // eslint-disable-line no-console
      clearLocalStorage(LOGOUT_WHITELIST);
      log('error', err);
      redirectToLogin();
      throw err;
    })
    .finally(accessTokenRefresh.cancel);
  return prom;
};
// We really only want to request the access token once when invoked, but because all axios and apollo requests can
// occur very quickly, we'll throttle the calls to just the first one.
// Basically every call to `refreshAccessToken` will get the first calls return value, which in our case is the promise
// of when it finishes.
export const refreshAccessToken = throttle(accessTokenRefresh, 5000, {
  trailing: false,
});
export const isLockdownBrowser = () => cookies.get('rldbci');

/**
 * Given url, should we redirect to SSO?
 * The return value is a boolean, in all cases.
 * @param {string} url - The url, as a string, on which we decide
 */
export const shouldRedirectToSSO = (url) => {
  // Unless the first level pages are login, logout - send them to SSO
  // No:
  // apclassroom.collegeboard.org/login
  // apclassroom.collegeboard.org/logout

  // SSO if pattern like "/78/assessments/assignments/173784"
  // this should not change behavior of apclassroom.collegeboard.org/login?directlogin=blah
  const logPageRegExp = RegExp('^/log(in|out)', 'i');
  const urlParsed = new URL(url);

  if (logPageRegExp.test(urlParsed.pathname)) {
    return false;
  }

  return true;
};

/**
 * Given url, which URL should we redirect to?
 * The return value is an URL string, in all cases.
 * @param {string} url - The url, as a string, on which we decide
 */
export const redirectTo = (url) => {
  if (shouldRedirectToSSO(url)) {
    return `${SSO_LOGIN_URL}?DURL=${url}`;
  }

  return `${ROS_FRONTEND_URL}`; // Remove DURL as MyAP does not respect it
};

/**
 * Given url currentLocation, redirect to the proper page if user
 * needs to authenticate.
 * Determine if we need to login: if not, there's nothing for us to do
 * The return value is an URL string, in all cases.
 * @param {string} currentLocation - The url, as a string, where we are
 */

export const sendForAuthentication = (currentLocation) => {
  if (process.env.ENV !== 'dev') {
    window.location.href = redirectTo(currentLocation);
  }
};

export const initHeap = (me = {}, shouldCaptureReplay = false) => {
  const isTeacher = me.userRole.includes('teacher');
  const isStudent = me.userRole.includes('student');

  if (isTeacher || isStudent) {
    const userId = me.id;
    const userProperties = {
      userId,
      role: isTeacher ? 'teacher' : 'student',
      program: 'ap',
    };
    if (window.auryc && shouldCaptureReplay) {
      window.auryc.addSessionProperties(userProperties);
    }
    if (window.heap) {
      window.heap.identify(me.initId);
      window.heap.addUserProperties(userProperties);
    }
  }
};
