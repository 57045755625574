import {
  MQ,
  PPC as PPC_RESOURCE,
  PE as PE_RESOURCE,
  SPP as SPP_RESOURCE,
} from 'chameleon-src/constants/subjects';
import {
  ASSESSMENT_PDF_STATUSES,
  ASSESSMENTS_DEFAULT_STATUS,
} from 'chameleon-src/constants/assessmentStatus';
import {
  isResourceVisibilityOverriddenByFF,
  isSubjectHasResource,
} from 'chameleon-src/utility/subjectResources';
import {
  ALL_RESOURCES,
  ASSIGNED_RESOURCES,
  UNASSIGNED,
} from '../SidebarWrapperTwoLevels/constants';

export const PPC = 'ppc';
export const TEACHER_AUTHORED = 'teacher-authored';
export const PE = 'practice-exam';
export const VIDEO = 'video';
export const SPP = 'spp';
export const PDF = 'pdf';
export const LEARNING_CHECKPOINTS = 'learning-checkpoint';
export const PERFORMANCE_TASKS = 'performance-task';

export const ALL_TAB = 'All';
const PROGRESS_CHECKS_TAB = 'Progress Checks';
const MY_QUIZZES_TAB = 'My Quizzes';
const QUIZZES_TAB = 'Quizzes';
const PRACTICE_EXAMS_TAB = 'Practice Exams';
const VIDEOS_TAB = 'Videos';
const SPP_TAB = 'Student Practice';
const PDF_TAB = 'PDFs';

const LEARNING_CHECKPOINTS_TAB = 'Learning Checkpoints';
const PERFORMANCE_TASKS_TAB = 'Performance Tasks';

const FILTER_TYPES = {
  PREAP: 'pre-ap',
  AP: 'fym',
  QB: 'qb',
};

export const isFilterVisible = (
  subjectId,
  status,
  filter,
  isStudent = false,
  afamEp26FullLaunchP1FF = false,
) => {
  const { value: tabId } = filter;

  if (
    status?.split(',')?.find((s) => filter?.excludeStatusValue?.includes(s))
  ) {
    return false;
  }
  if (tabId === PPC) {
    return isSubjectHasResource(subjectId, PPC_RESOURCE);
  }
  if (tabId === TEACHER_AUTHORED) {
    return isSubjectHasResource(subjectId, MQ);
  }
  if (tabId === PE) {
    return isSubjectHasResource(subjectId, PE_RESOURCE);
  }
  if (tabId === SPP) {
    return isSubjectHasResource(subjectId, SPP_RESOURCE);
  }
  if (tabId === VIDEO) {
    return (
      isSubjectHasResource(subjectId, VIDEO) ||
      isResourceVisibilityOverriddenByFF(subjectId, VIDEO, isStudent, {
        afam_ep26_full_launch_p1: afamEp26FullLaunchP1FF,
      })
    );
  }

  return true;
};

export const TYPE_FILTERS = (
  studentPracticeProblems,
  pdfAssignmentsFF,
  isAllResources,
  subjectType = 'fym',
) => {
  const defaultFilters = [
    {
      name: ALL_TAB,
      value: '',
      values: [PPC, TEACHER_AUTHORED, PE, VIDEO, SPP],
      excludeStatusValue: [
        ALL_RESOURCES,
        ASSIGNED_RESOURCES,
        UNASSIGNED,
        ...ASSESSMENT_PDF_STATUSES,
      ],
    },
    {
      name: PROGRESS_CHECKS_TAB,
      value: PPC,
    },
    {
      name: MY_QUIZZES_TAB,
      value: TEACHER_AUTHORED,
    },
    {
      name: PRACTICE_EXAMS_TAB,
      value: PE,
    },
    {
      name: VIDEOS_TAB,
      value: VIDEO,
    },
    ...(pdfAssignmentsFF && isAllResources
      ? [
          {
            name: PDF_TAB,
            value: PDF,
            params: {
              status: ASSESSMENTS_DEFAULT_STATUS,
            },
          },
        ]
      : []),
    ...(studentPracticeProblems
      ? [
          {
            name: SPP_TAB,
            value: SPP,
            trackingId: 'student-practice-pin',
          },
        ]
      : []),
  ];

  const FILTERS = {
    [FILTER_TYPES.PREAP]: [
      !isAllResources
        ? {
            name: ALL_TAB,
            value: '',
            values: [
              LEARNING_CHECKPOINTS,
              PERFORMANCE_TASKS,
              PDF,
              TEACHER_AUTHORED,
            ],
          }
        : null,
      {
        name: LEARNING_CHECKPOINTS_TAB,
        value: LEARNING_CHECKPOINTS,
      },
      {
        name: PERFORMANCE_TASKS_TAB,
        value: PERFORMANCE_TASKS,
      },
      isAllResources
        ? {
            name: PDF_TAB,
            value: PDF,
          }
        : null,
      {
        name: MY_QUIZZES_TAB,
        value: TEACHER_AUTHORED,
      },
    ],
    [FILTER_TYPES.AP]: defaultFilters,
    [FILTER_TYPES.QB]: defaultFilters,
  };

  return FILTERS[subjectType]?.filter?.(Boolean) || defaultFilters;
};

export const TYPE_FILTERS_STUDENT = (
  studentPracticeProblems,
  pdfAssignmentsFF,
  isAllResources,
  subjectType = 'fym',
) => {
  const defaultFilters = [
    {
      name: ALL_TAB,
      value: '',
      values: [PPC, TEACHER_AUTHORED, PE, VIDEO, SPP],
      excludeStatusValue: [
        ALL_RESOURCES,
        ASSIGNED_RESOURCES,
        UNASSIGNED,
        ...ASSESSMENT_PDF_STATUSES,
      ],
    },
    {
      name: PROGRESS_CHECKS_TAB,
      value: PPC,
    },
    {
      name: PRACTICE_EXAMS_TAB,
      value: PE,
    },
    {
      name: QUIZZES_TAB,
      value: TEACHER_AUTHORED,
    },
    ...(studentPracticeProblems
      ? [
          {
            name: SPP_TAB,
            value: SPP,
            trackingId: 'student-practice-pin',
          },
        ]
      : []),
    {
      name: VIDEOS_TAB,
      value: VIDEO,
    },
    ...(pdfAssignmentsFF && isAllResources
      ? [
          {
            name: PDF_TAB,
            value: PDF,
            params: {
              status: ASSESSMENTS_DEFAULT_STATUS,
            },
          },
        ]
      : []),
  ];

  const FILTERS = {
    [FILTER_TYPES.PREAP]: [
      !isAllResources
        ? {
            name: ALL_TAB,
            value: '',
            values: [LEARNING_CHECKPOINTS, PERFORMANCE_TASKS, TEACHER_AUTHORED],
          }
        : null,
      {
        name: LEARNING_CHECKPOINTS_TAB,
        value: LEARNING_CHECKPOINTS,
      },
      {
        name: PERFORMANCE_TASKS_TAB,
        value: PERFORMANCE_TASKS,
      },
      {
        name: QUIZZES_TAB,
        value: TEACHER_AUTHORED,
      },
    ],
    [FILTER_TYPES.AP]: defaultFilters,
    [FILTER_TYPES.QB]: defaultFilters,
  };

  return FILTERS[subjectType]?.filter?.(Boolean) || defaultFilters;
};
